body { background-color: #ffffff; }
body { color: var(--clr-977); }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 30px;

@media #{$medium-up} {
font-size: 40px;

}
@media #{$large-up} {
font-size: 34px;

}
}
h3 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 20px;

}
}
h5 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.2;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$large-up} {
font-size: 18px;

}
}
.me-Quote .quote-body {
font-family: 'Arapey';
font-weight: normal;
font-style: italic;
line-height: 1.5;
letter-spacing: 0;
font-size: 20px;

@media #{$medium-up} {
font-size: 22px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
font-family: 'Poppins';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
color: 21;
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.1;
letter-spacing: 0;
font-size: 30px;

@media #{$medium-up} {
font-size: 38px;

}
}
summary {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Poppins';
font-weight: 700;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
@media #{$large-up} {
font-size: 20px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Poppins';
font-weight: 600;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 25px;

@media #{$medium-up} {
font-size: 35px;

}
@media #{$large-up} {
font-size: 45px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Poppins';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

}
body {
font-family: 'Poppins';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: var(--clr-977);}
/* Primary:2 */
.MES2 {
background-color: var(--clr-978);
color: #ffffff;
 }
/* Primary:3 */
.MES3 {
background-color: var(--clr-978);
color: #ffffff;
 }
.MES3 {
background-color: var(--clr-978);
color: #ffffff;
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: #ffffff;
 }
 }
cite.MEC3{
color: #ffffff;
}
/* Light:4 */
.MES4 {
background-color: var(--clr-979);
 }
/* Light:5 */
.MES5 {
background-color: var(--clr-979);
 }
.MES5 {
background-color: var(--clr-979);
 }
/* Dark:6 */
.MES6 {
background-color: var(--clr-977);
color: #ffffff;
 }
/* Dark:7 */
.MES7 {
background-color: var(--clr-977);
color: #ffffff;
 }
.MES7 {
background-color: var(--clr-977);
color: #ffffff;
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: #ffffff;
 }
 }
cite.MEC7{
color: #ffffff;
}
/* Secondary:8 */
.MES8 {
background-color: #000000;
 }
/* Secondary:9 */
.MES9 {
background-color: #000000;
 }
.MES9 {
background-color: #000000;
 }
/* Alternate:10 */
.MES10 {
background-color: var(--clr-980);
 }
/* Alternate:11 */
.MES11 {
background-color: var(--clr-980);
 }
.MES11 {
background-color: var(--clr-980);
 }
/* Shade 1:12 */
.MES12 {
background-color: var(--clr-981);
 }
/* Shade 1:13 */
.MES13 {
background-color: var(--clr-981);
 }
.MES13 {
background-color: var(--clr-981);
 }
/* Slider background:14 */
.MES14 {
background-color: var(--clr-982);
color: #ffffff;
 }
.MES14 {
background-color: var(--clr-982);
color: #ffffff;
h1.MEC14, h2.MEC14, h3.MEC14, h4.MEC14, h5.MEC14, h6.MEC14 { color: #ffffff;
 }
 }
a.MEC14 { color: #ffffff;
 }
cite.MEC14{
color: #ffffff;
}
/* Shade 2:19 */
.MES19 {
background-color: var(--clr-983);
 }
/* Green Transparent:15 */
.MES15 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-984);}
color: #ffffff;
padding: 15px 30px;

@media #{$large-up} {
padding: 0 50px;

}
 }
.MES15 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-984);}
color: #ffffff;
padding: 15px 30px;

@media #{$large-up} {
padding: 0 50px;

}
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: #ffffff;
 }
 }
cite.MEC15{
color: #ffffff;
}
/* Hollow:16 */
.MES16 {
background-color: transparent;
&:hover {background-color: transparent;}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

border-width: 1px;
border-style: solid;
border-color: #ffffff;
&:hover { border-color: #ffffff; }
 }
/* Dark Transparent:17 */
.MES17 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-985);}
color: #ffffff;
padding: 15px 30px;

@media #{$large-up} {
padding: 0 50px;

}
 }
.MES17 {
background-color: transparent;
&:hover, &.hover { background-color: var(--clr-985);}
color: #ffffff;
padding: 15px 30px;

@media #{$large-up} {
padding: 0 50px;

}
h1.MEC17, h2.MEC17, h3.MEC17, h4.MEC17, h5.MEC17, h6.MEC17 { color: #ffffff;
 }
 }
cite.MEC17{
color: #ffffff;
}
/* Green:18 */
.MES18 {
background-color: var(--clr-978);
&:hover {background-color: var(--clr-980);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

 }
/* Shade 2:20 */
.MES20 {
background-color: var(--clr-983);
 }
.MES20 {
background-color: var(--clr-983);
 }
/* Top Menu:21 */
nav.me-Menu.MES21 {
& .menu-item.MEC21, & .menu-item.MEC21 > div.MEC21{ & > a.MEC21{color: var(--clr-977);
}
  }
&.horizontal > .menu-item.MEC21 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC21 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC21 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC21 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 15px;}

& .sub-menu .menu-item a{padding: 15px;}



&.horizontal .menu-item.MEC21:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid transparent;}}
&.vertical .menu-item.MEC21:hover {& > .pointer-wrap > .pointer{border-right: 10px solid transparent;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC21{background-color: transparent; &:hover {background-color: 1;}
}
& .menu-item.MEC21, & .menu-item.MEC21 > div.MEC21{ & > a.MEC21{}
  }

}
}
 }
/* Top Menu white:22 */
nav.me-Menu.MES22 {
& .menu-item.MEC22, & .menu-item.MEC22 > div.MEC22{ & > a.MEC22{color: #ffffff;
}
 &:hover > a.MEC22{color: var(--clr-979);
}
 }
&.horizontal > .menu-item.MEC22 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC22 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC22 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC22 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 0 20px;}

& .sub-menu .menu-item a{padding: 5px 25px;}



&.horizontal .menu-item.MEC22:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid var(--clr-982-flat);}}
&.vertical .menu-item.MEC22:hover {& > .pointer-wrap > .pointer{border-right: 10px solid var(--clr-982-flat);
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC22{background-color: var(--clr-982);}

}
}
 }
/* Rotate Menu Wrap Hover:39 */
.MES39 {
background-color: var(--clr-977);
&:hover, &.hover { background-color: var(--clr-977);}
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES39 {
background-color: var(--clr-977);
&:hover, &.hover { background-color: var(--clr-977);}
border-radius: 20px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Rotate Menu:24 */
nav.me-Menu.MES24 {
.menu-item.MEC24{background-color: transparent; &:hover {background-color: transparent;}
}
& .menu-item.MEC24, & .menu-item.MEC24 > div.MEC24{ & > a.MEC24{color: #ffffff;
text-transform: capitalize;
}
 &:hover > a.MEC24{color: #ffffff;
}
 }
&.horizontal > .menu-item.MEC24 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC24 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC24 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC24 .sub-menu { border:1px;
 }
& .sub-menu .menu-item a{padding: 5px;}



&.horizontal .menu-item.MEC24:hover {& > .pointer-wrap > .pointer{border-bottom: 10px solid transparent;}}
&.vertical .menu-item.MEC24:hover {& > .pointer-wrap > .pointer{border-right: 10px solid transparent;
}}

.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {
.menu-item.MEC24{background-color: transparent; &:hover {background-color: 1;}
}
& .menu-item.MEC24, & .menu-item.MEC24 > div.MEC24{ & > a.MEC24{color: #ffffff;
font-size: 24px;
@media #{$medium-up} {
font-size: 33.6px;
}@media #{$large-up} {
font-size: 44.8px;
}text-transform: capitalize;
}
 &:hover > a.MEC24{color: 2;
}
 }

}
}
 }
/* Cart Icon:23 */
.MES23 {
 & .cart-panel{  }
 color: #ffffff;
font-size: 14.4px;
@media #{$medium-up} {
font-size: 16px;
}}/* Rotate Menu Wrap:25 */
.MES25 {
background-color: var(--clr-977);
&:hover, &.hover { background-color: var(--clr-977);}
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES25 {
background-color: var(--clr-977);
&:hover, &.hover { background-color: var(--clr-977);}
border-radius: 100px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* White Heading:26 */
.MES26 {
 }
.MES26 {
h1.MEC26, h2.MEC26, h3.MEC26, h4.MEC26, h5.MEC26, h6.MEC26 { color: #ffffff;
 }
 }
/* Spacer:27 */
.MES27 {
padding: 10px;

@media #{$medium-up} {
padding: 20px;

}
 }
/* Product Divider:28 */
.MES28 {
& > hr {border-width: 1px 0 0 0;}
& > hr {border-color: var(--clr-986) transparent transparent transparent;}
& > hr {border-top-style: solid;}
 }
/* Grey Heading:29 */
.MES29 {
 }
.MES29 {
h1.MEC29, h2.MEC29, h3.MEC29, h4.MEC29, h5.MEC29, h6.MEC29 { color: var(--clr-982);
 }
 }
/* Footer area:30 */
.MES30 {
background-color: #000000;
color: #ffffff;
font-size: 12.8px;
 }
.MES30 {
background-color: #000000;
color: #ffffff;
font-size: 12.8px;
h1.MEC30, h2.MEC30, h3.MEC30, h4.MEC30, h5.MEC30, h6.MEC30 { color: #ffffff;
 }
 }
a.MEC30 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC30{
color: #ffffff;
font-size: 12.8px;
}
/* Credit area Footer:31 */
.MES31 {
background-color: var(--clr-977);
color: var(--clr-987);
font-size: 11.2px;
padding: 0;

 }
.MES31 {
background-color: var(--clr-977);
color: var(--clr-987);
font-size: 11.2px;
padding: 0;

h1.MEC31, h2.MEC31, h3.MEC31, h4.MEC31, h5.MEC31, h6.MEC31 { color: #ffffff;
 }
 }
a.MEC31 { color: #ffffff;
&:hover { color: #ffffff;}
 }
cite.MEC31{
color: var(--clr-987);
font-size: 11.2px;
}
/* White panel:32 */
.MES32 {
background-color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px 0;

}
 }
.MES32 {
background-color: #ffffff;
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px 0;

}
 }
/* Light Grey panel:33 */
.MES33 {
background-color: var(--clr-983);
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px 0;

}
 }
.MES33 {
background-color: var(--clr-983);
padding: 15px;

@media #{$medium-up} {
padding: 30px;

}
@media #{$large-up} {
padding: 50px 0;

}
 }
/* Black Buton:34 */
.MES34 {
background-color: var(--clr-977);
&:hover {background-color: var(--clr-987);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 20px 30px;

 }
/* Shade 3:35 */
.MES35 {
background-color: #ffffff;
 }
/* Shade 3:36 */
.MES36 {
background-color: #ffffff;
 }
.MES36 {
background-color: #ffffff;
 }
/* Pop out white panel:37 */
.MES37 {
background-color: #ffffff;
border-radius: 30px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 30px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 30px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES37 {
background-color: #ffffff;
border-radius: 30px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 30px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 30px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Pop out grey panel:38 */
.MES38 {
background-color: var(--clr-983);
border-radius: 30px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 30px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 30px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
.MES38 {
background-color: var(--clr-983);
border-radius: 30px;
-webkit-transform: translateZ(0);
overflow: hidden;
padding: 30px 10px;

@media #{$medium-up} {
padding: 20px;

}
@media #{$large-up} {
padding: 30px;

}
border-width: 1px;
border-style: solid;
border-color: transparent;
 }
/* Order Button:40 */
.MES40 {
background-color: var(--clr-981);
&:hover {background-color: var(--clr-980);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 15px 20px;

border-width: 1px;
border-style: solid;
border-color: transparent;
&:hover { border-color: transparent; }
 }
/* Responsive Menu:41 */
nav.responsive-menu {
.menu-item.MEC41{background-color: var(--clr-978);}
& .menu-item.MEC41, & .menu-item.MEC41 > div.menu-item-wrap{ & > a.MEC41, & > i{color: #ffffff;
font-size: 19.2px;
@media #{$medium-up} {
font-size: 24px;
}text-transform: uppercase;
text-align: left;
}
  }
& .menu-item.MEC41 { border:0;
border-color: #ffffff;border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 5px 10px;}

& .sub-menu{.menu-item.MEC41{background-color: 10;}
& .menu-item.MEC41, & .menu-item.MEC41 > div.menu-item-wrap{ & > a.MEC41, & > i{color: 2;
font-size: 19.2px;
@media #{$medium-up} {
font-size: 24px;
}text-transform: uppercase;
text-align: left;
}
  }
 .sub-menu {.menu-item.MEC41{background-color: 16;}
& .menu-item.MEC41, & .menu-item.MEC41 > div.menu-item-wrap{ & > a.MEC41, & > i{color: 5;
font-size: 19.2px;
@media #{$medium-up} {
font-size: 24px;
}text-transform: uppercase;
text-align: left;
}
  }
}}

 }
/* Black Buton:42 */
.MES42 {
background-color: var(--clr-987);
&:hover {background-color: var(--clr-977);}
color: #ffffff;
&:hover { color: #ffffff;}
border-radius: 50px;
padding: 10px 30px;

 }
/* White text Panel:43 */
.MES43 {
color: #ffffff;
 }
.MES43 {
color: #ffffff;
h1.MEC43, h2.MEC43, h3.MEC43, h4.MEC43, h5.MEC43, h6.MEC43 { color: #ffffff;
 }
 }
a.MEC43 { color: #ffffff;
&:hover { color: #ffffff;}
 }
.MEC43 li {color: #ffffff;}
cite.MEC43{
color: #ffffff;
}
